import { PageProps } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import ParticipantList from 'manage-tritag/components/referee/profile'
import Navbar from '../../../components/navbar'
import MobileNavbar from '../../../components/navbar/mobile'

const RegisterPlayerProfilePage = (props: PageProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <ParticipantList />
    </>
  )
}

export default withAuthenticationRequired(RegisterPlayerProfilePage)
