import React, { useEffect } from 'react'
import { styled } from '@material-ui/core'

interface PageHeaderProps {
  title: string
}

const StyledPageHeader = styled('h2')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        fontSize: '1.2rem',
      },
    }
  }}
  color: #008174;
  font-weight: bold;
  margin-bottom: 30px;
`

const PageHeader: React.FC<PageHeaderProps> = ({ title = '' }) => {
  useEffect(() => {
    return () => {}
  }, [title])

  return <StyledPageHeader>{title}</StyledPageHeader>
}

export default PageHeader
