import api from '..'

export interface Address {
  formatted: string
  line_1: string
  line_2: string
  city: string
  state: string
  postcode: string
  country: string
  location: {
    lat: number
    lng: number
  }
}

export interface Participant {
  _id: string
  ttr_id: number
  firstName: string
  lastName: string
  dateOfBirth: string
  gender: string
  contact: {
    email: string
    phone: string
  }
  emergencyContact: {
    name: string
    phone: string
  }
  address: Address
  headshot: string
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type ParticipantsResponse = Participant[]

export const participantsApi = api.injectEndpoints({
  endpoints: build => ({
    getParticipants: build.query<ParticipantsResponse, string>({
      query: () => {
        return {
          url: '/mobile/user/participants/',
          // headers: { Authorization: `Bearer ${token}` },
        }
      },
      providesTags: ['participants'],
    }),
    getParticipant: build.query<Participant, string>({
      query: id => `participants/${id}`,
      providesTags: ['participant'],
    }),
    addParticipant: build.mutation({
      query: body => ({
        url: 'participants',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['participants'],
    }),
    updateParticipant: build.mutation({
      query: body => {
        const formData = decryptData(body.get('formData'))
        return {
          url: `participants/${formData.id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['participants', 'participant'],
    }),
    deleteParticipant: build.mutation({
      query: id => ({
        url: `participants/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['participants'],
    }),
  }),
})

export const {
  useGetParticipantsQuery,
  useGetParticipantQuery,
  useAddParticipantMutation,
  useUpdateParticipantMutation,
  useDeleteParticipantMutation,
  useLazyGetParticipantsQuery,
} = participantsApi
